import React from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import MAP_STYLES from "./map-styles";
import {useTranslation} from "gatsby-plugin-react-i18next";

const MapContainer = () => {
  const { t } = useTranslation();
  const mapContainerStyle = {
    height: "45vh",
    width: "100%",
  };
  const defaultCenter = {
    lat: 52.23109954752445,
    lng: 21.036545236203988,
  };
  const options = {
    styles: MAP_STYLES,
  };
  const [selected, setSelected] = React.useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAP_API_KEYS,
  });
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={defaultCenter}
        options={options}
      >
        <Marker
          key={"1"}
          position={defaultCenter}
          icon={{
            url: "/InHub_pin.png",
            scaledSize: new window.google.maps.Size(40, 60),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(20, 30),
          }}
          onClick={() => {
            setSelected(true);
          }}
        />
        {selected ? (
          <InfoWindow
            position={defaultCenter}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
              <h2> {t("contact.form.map-name")}</h2>
              <p> {t("contact.info.address")}</p>
              <p>
                <a href="https://maps.google.com/maps?ll=52.208872,21.008658&z=16&t=m&hl=pl&gl=PL&mapclient=embed&cid=5810553001076154245">
                  {t("contact.form.map-bigger")}
                </a>
              </p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
};

export default MapContainer;
