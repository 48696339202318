import { graphql } from 'gatsby';
import ContactPage from '../views/contact';

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
